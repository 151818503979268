import React, { useContext } from 'react'
import { DefaultDParams } from '../../../app/shared/interfaces';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import useMonthlyClosureDetailService from '../../../app/services/hooks/useMonthlyClosureDetailService';
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { MonthlyClosureDetailDataForm } from '../../../app/models/MonthlyClosureDetail';

interface Props {
    detail: MonthlyClosureDetailDataForm;
    statuses: DefaultDParams[];
    callbackSuccess?: () => void;
}

const MonthlyClosureDetailStatusChange = ({
    detail,
    statuses,
    callbackSuccess,
}: Props) => {

    const { showLoading, hideLoading } = useContext(AppContext);
    const {  
        changeMonthlyClosureDetailsStatus,
        canChangeMonthlyClosureDetailsStatus
    } = useMonthlyClosureDetailService();

    const changeStatus = (status: string, detailId: number | undefined) => {
        if(!detailId) return null
        if (showLoading) showLoading('loading', 'cargando..');

        canChangeMonthlyClosureDetailsStatus(detailId, status, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                confirmChange(status, detailId);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };


    const confirmChange = (status: string, detailId: number) => {
        
        const obj = {'status': status}
        const _text = 'Está a punto de cambiar el estado del detalle de cierre #' + detailId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, cambiar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Actualizando estado...');
                        changeMonthlyClosureDetailsStatus(detailId, obj, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                if(callbackSuccess) callbackSuccess();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El detalle de cierre no se ha cambiado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    }
    
    return (
        <select
            className="form-control"
            value={detail.status_obj?.id}
            onChange={(e) => changeStatus(e.target.value, detail.id)}
        >
            {statuses
                .map((status) => {
                    detail.status_obj?.id !== status.id
                    return (
                        <option key={status.id} value={status.id}>
                            {status.name}
                        </option>
                    );
                })}
        </select>
    )
}

export default MonthlyClosureDetailStatusChange
