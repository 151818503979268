import React from 'react';
import { MonthlyClosureDataForm } from '../../../../app/models/MonthlyClosure';
import { formatDateToMonth } from '../../../../helpers';

interface Props {
    monthlyClosureDataForm: MonthlyClosureDataForm;
}

const MonthlyClosureShowForm = ({ monthlyClosureDataForm }: Props) => {

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Mes</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="society"
                        className="form-control"
                        readOnly={true}
                        value={formatDateToMonth(monthlyClosureDataForm.closing_date)}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Cerrado por</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="client"
                        className="form-control"
                        readOnly={true}
                        value={monthlyClosureDataForm.created_user}
                    />
                </div>
            </div>
        </>
    );
};

export default MonthlyClosureShowForm;
